<template lang="pug">
  .inventory-group-form
    AppOverlayLoader(:state="loading")
    BTabs(fill)
      BTab(:title="$t('inventory_group_matching.inventory_group')")
        .field
          .title
            FormFieldLabel(
              required
              :title="$t('inventory_group_matching.attributes.name')"
            )
          .input-name
            .inventory-group-name(v-if="isExistingInventoryGroup") {{ inventoryGroupData.name }}
            .d-flex.align-items-center.w-100(v-else)
              input(
                :class="{ 'is-invalid': inventoryGroupExists }"
                :value="inventoryGroupData.name"
                @input="handleChangeName"
              )
              FaIcon.m-l-10(
                v-if="inventoryGroupExistsLoading"
                icon="spinner"
                spin
              )
            BFormInvalidFeedback(
              v-if="inventoryGroupExists"
              :state="false"
            ) {{ $t("inventory_group_matching.name_exists") }}
        .field(v-if="currentOrganization.dp")
          .title
            FormFieldLabel(:title="$t('inventory_group_matching.attributes.dp')")
          .input.dp
            AppCheckbox.highlight-checked(
              :value="inventoryGroupData.dp"
              :disabled="!!inventoryGroupData.id"
              @change="handleChangeDp"
            ) 
        .field(v-for="ota in otas")
          .title
            FormFieldLabel(:title="ota.name")
          .input
            .matched-sources-inventory-group(
              v-if="inventoryGroup.sources_inventory_groups[ota.id]"
              :class="ota.rawName"
            ) {{ inventoryGroup.sources_inventory_groups[ota.id].name }}
            AppDropdown(
              v-else
              :class="ota.rawName"
              allow-empty
              close-on-select
              searchable
              :lazy="false"
              size="small"
              :value="inventoryGroupData.sources_inventory_groups[ota.id]"
              :items="groupedSourcesInventoryGroups[ota.id]"
              :is-item-disabled="isSourcesDisabled"
              @select="handleChangeSources(ota, $event)"
              @remove="handleChangeSources(ota, {})"
            )
      BTab(
        :title="$t('inventory_group_matching.master_shop')"
        :disabled="!isExistingInventoryGroup"
      )
        .field(
          v-for="ota in otas"
          v-if="otaWithMasterShop.includes(ota.rawName)"
        )
          .title
            FormFieldLabel(:title="ota.name")
          .input
            .dropdown(v-if="isEmpty(selectedMasterShop(sourcesShopsByOta[ota.id]))")
              AppDropdown(
                v-if="inventoryGroup.sources_inventory_groups[ota.id]"
                :class="ota.rawName"
                allow-empty
                close-on-select
                searchable
                :lazy="false"
                size="small"
                :items="sourcesShopsByOta[ota.id]"
                :value="selectedMasterShops[ota.id]"
                @select="handleSelectMasterShop(ota.id, $event)"
              )
              AppDropdown(
                v-else
                :disabled="true"
              )
            .matched-sources-inventory-group(
              v-else
              :class="ota.rawName"
            ) {{ selectedMasterShop(sourcesShopsByOta[ota.id]).name }}
    .actions
      AppButton.close(
        title="actions.close"
        @click="$emit('close')"
      )
      AppSaveButton.save(
        :disabled="isSaveDisabled"
        title="actions.save_changes"
        @save="handleSave"
      )
</template>

<script>
  // misc
  import { groupBy, map, forEach, isNil, isEmpty, find, transform } from "lodash-es"
  import { showToast } from "@/helpers/toasts"
  import { appDebounce } from "@/helpers/common"
  import { OTA_WITH_MASTER_SHOP } from "@/config/constants"

  // store modules
  import sourcesShopsModule from "@/config/store/shared_inventory_management/sources_shops"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import { mapGetters } from "vuex"

  // components
  import { BTabs, BTab } from "bootstrap-vue"

  const DEFAULT_INVENTORY_GROUP_OBJECT = {
    name: "",
    dp: false,
    sources_inventory_groups: {}
  }

  const sourcesShopsMixin = withStoreModule(sourcesShopsModule, {
    name: "sharedInventoryMasterSourcesShops",
    actions: { updateSourcesShops: "UPDATE_ITEMS" }
  })

  export default {
    components: {
      FormFieldLabel: () => import("@/components/elements/FormFieldLabel"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppSaveButton: () => import("@/components/elements/AppButton/Save"),
      AppButton: () => import("@/components/elements/AppButton"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader"),
      AppCheckbox: () => import("@/components/elements/AppCheckbox"),
      BTabs,
      BTab
    },

    mixins: [sourcesShopsMixin],

    props: {
      inventoryGroup: {
        type: Object,
        default: () => DEFAULT_INVENTORY_GROUP_OBJECT
      },
      action: {
        type: Function,
        default: () => {}
      },
      successActionCallbacks: {
        type: Array,
        default: () => new Array()
      },
      otas: {
        type: Array,
        default: () => new Array()
      },
      sourcesInventoryGroups: {
        type: Array,
        default: () => new Array()
      },
      selectedSources: {
        type: Object,
        default: () => new Object()
      },
      loading: {
        type: Boolean,
        default: false
      },
      inventoryGroupExistsRequest: {
        type: Function,
        default: () => new Function()
      },
      sourcesShopsByOta: {
        type: Object,
        default: () => new Object()
      }
    },

    data() {
      return {
        inventoryGroupExists: false,
        inventoryGroupExistsLoading: false,
        inventoryGroupData: { ...this.inventoryGroup },
        masterShops: {},
        otaWithMasterShop: OTA_WITH_MASTER_SHOP
      }
    },

    computed: {
      ...mapGetters(["currentOrganization"]),

      isSaveDisabled() {
        return isEmpty(this.inventoryGroupData.name) || this.inventoryGroupExists || this.inventoryGroupExistsLoading
      },

      filteredSourcesgroups({ sourcesInventoryGroups, inventoryGroupData }) {
        return sourcesInventoryGroups.filter(({ dp }) => dp === inventoryGroupData.dp)
      },

      groupedSourcesInventoryGroups({ filteredSourcesgroups }) {
        return groupBy(filteredSourcesgroups, "ota_id")
      },

      isExistingInventoryGroup({ inventoryGroup }) {
        return !isNil(inventoryGroup.id)
      },

      actionType({ isExistingInventoryGroup }) {
        return isExistingInventoryGroup ? "update" : "create"
      },

      selectedMasterShops({ sourcesShopsByOta, masterShops }) {
        return transform(
          sourcesShopsByOta,
          (obj, shops, otaID) => {
            const shop = this.selectedMasterShop(shops) || masterShops[otaID]
            obj[otaID] = shop
            return obj
          },
          {}
        )
      }
    },

    methods: {
      isEmpty,

      handleChangeDp(dp) {
        this.inventoryGroupData = { ...this.inventoryGroupData, dp, sources_inventory_groups: {} }
      },

      handleChangeName(event) {
        this.inventoryGroupData.name = event.currentTarget.value
        if (this.inventoryGroupData.name) {
          this.inventoryGroupExistsLoading = true
          this.checkInventoryGroupExistsDebounced(this.inventoryGroupData.name)
        }
      },

      isSourcesDisabled({ id, ota_id }) {
        return this.selectedSources[ota_id]?.includes(id)
      },

      handleChangeSources(ota, value) {
        this.inventoryGroupData = {
          ...this.inventoryGroupData,
          sources_inventory_groups: {
            ...this.inventoryGroupData.sources_inventory_groups,
            [ota.id]: value
          }
        }
      },

      checkInventoryGroupExistsDebounced: appDebounce(function(name) {
        this.inventoryGroupExistsRequest(name)
          .then(() => {
            this.inventoryGroupExists = true
          })
          .catch(() => {
            this.inventoryGroupExists = false
          })
          .finally(() => (this.inventoryGroupExistsLoading = false))
      }),

      async handleSave() {
        try {
          await this.action({
            // mapped inventory group params
            ...this.inventoryGroupData,
            sources_inventory_group_ids: map(this.inventoryGroupData.sources_inventory_groups, "id")
          })
          if (!isEmpty(this.masterShops)) {
            await this.updateSourcesShops({ shops: this.masterShops, inventoryGroupId: this.inventoryGroupData.id })
          }

          forEach(this.successActionCallbacks, callback => callback())
          this.$emit("close")
        } catch (e) {
          showToast({ text: this.$t(`activerecord.errors.models.inventory_group.could_not_${this.actionType}`) })
        }
      },

      selectedMasterShop(shops) {
        return find(shops, ({ master }) => master)
      },

      handleSelectMasterShop(otaID, shop) {
        this.masterShops = { ...this.masterShops, [otaID]: shop }
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  .inventory-group-form
    font-size: 0.8rem
    position: relative

    .tabs
      ::v-deep
        .nav-item:has(.disabled)
          cursor: not-allowed

    .field
      align-items: center
      border-bottom: 1px solid $default-gray-light
      display: flex

      .title,
      .input,
      .input-name
        width: 50%

      .title
        font-weight: 500

      .input-name
        border: 1px solid transparent
        padding: 6px

        input
          border: 1px solid $border-element-color
          border-radius: 4px
          color: $link-color
          height: 30px
          padding: 0 5px
          width: 100%

          &.is-invalid
            +default-invalid-input

      .input
        ::v-deep
          .app-input,
          .dropdown-toggle
            height: 30px
            width: 100%

      .matched-sources-inventory-group
        align-items: center
        display: flex
        height: 44px
        margin-left: 8px

    .ota-matchings
      .header
        display: flex
        text-align: center

      .ota
        align-items: center
        display: flex

      .ota-name
        width: 20%
      .sources-inventory-group,
      .master-shop
        width: 40%

      .matchings
        align-items: center
        display: flex
        width: 80%

        .sources-inventory-group,
        .master-shop
          width: 50%


    .actions
      align-items: center
      display: flex
      justify-content: space-evenly
      margin-top: 15px

      .close
        opacity: 1
        border: 1px solid $default-purple
        color: $default-purple
</style>
