import { api } from "@/config"
import { transform } from "lodash-es"

const extractShops = shops => {
  return transform(
    shops,
    (obj, shop, ota_id) => {
      obj.push({ ota_id, sources_shop_id: shop.id })
    },
    []
  )
}

export default ({ baseURI }) => ({
  UPDATE_ITEMS: async ({ commit }, { shops, inventoryGroupId }) => {
    const params = { shops: extractShops(shops), inventory_group_id: inventoryGroupId }
    const response = await api.put(baseURI, params)

    commit("SET_ITEMS", response.data.data)
  }
})
