import { remove } from "lodash-es"

const removeItem = (selectedItems, { ota_id, id }) => remove(selectedItems[ota_id], id_ => id_ === id)

export default () => ({
  SET_SELECTED_ITEMS: (state, payload) => (state.selectedItems = payload),
  SET_SELECTED_ITEMS_LOADING: (state, payload) => (state.selectedItemsLoading = payload),

  ADD_SELECTED_ITEM: (state, { item, oldItem }) => {
    const selectedItems = { ...state.selectedItems }

    if (oldItem) removeItem(selectedItems, oldItem)

    selectedItems[item.ota_id] ||= []
    selectedItems[item.ota_id].push(item.id)
    state.selectedItems = selectedItems
  },

  REMOVE_SELECTED_ITEM: (state, item) => {
    const selectedItems = { ...state.selectedItems }

    removeItem(selectedItems, item)
    state.selectedItems = selectedItems
  }
})
