import { api } from "@/config"

export default ({ baseURI }) => ({
  FETCH_SELECTED_ITEMS: async ({ commit }) => {
    commit("SET_SELECTED_ITEMS_LOADING", true)
    try {
      const { data } = await api.get(`${baseURI}/selected`)
      commit("SET_SELECTED_ITEMS", data.data)
    } finally {
      commit("SET_SELECTED_ITEMS_LOADING", false)
    }
  }
})
