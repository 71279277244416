import StoreItemsModule from "@/config/store/StoreItemsModule"
import { withLoading } from "@/config/store/StoreItemsModule/helpers"

import { groupBy } from "lodash-es"

import extractMutations from "./mutations"
import extractActions from "./actions"

export default baseURI => {
  const store = new StoreItemsModule({
    baseURI,
    presetActions: ["fetch"]
  })

  store.mergeState({
    selectedItems: {},
    selectedItemsLoading: false
  })

  store.mergeGetters({
    groupedByOta: ({ items }) => groupBy(items, "ota_id")
  })

  store.mergeMutations(extractMutations())
  store.mergeActions(extractActions({ baseURI }), withLoading)

  return store
}
