import StoreItemsModule from "@/config/store/StoreItemsModule"
import { withLoading } from "@/config/store/StoreItemsModule/helpers"

import extractActions from "./actions"

const BASE_URI = "/shared_inventories/sources_shops"

const store = new StoreItemsModule({
  baseURI: BASE_URI,
  presetActions: ["fetch"]
})

store.mergeState({
  items: {}
})

store.mergeActions(extractActions({ baseURI: BASE_URI }), withLoading)

export default store
