<template lang="pug">
  .inventory-group-matching.container-fluid
    AppOverlayLoader(:state="inventoryGroupsLoading || sourcesShopsLoading")
    TopBar(
      :hidden-elements="['save-button']"
      @add-new="handleAddNew"
      @search="handleSearch"
    )
    InventoryGroupsTable(
      :inventory-groups="inventoryGroups"
      :otas="otas"
      :sorting-data="sortingData"
      :sources-shops-by-inventory-groups="sourcesShopsByInventoryGroups"
      @sorting="handleSorting"
      @edit="handleEdit"
      @delete="handleDelete"
    )
    AppPagination(
      :current-page="pagination.current_page"
      :per-page="pagination.per_page"
      :total="pagination.total_count"
      :auto-scroll-on-pagination="{ target: '.inventory-group-table' }"
      @change-pagination-data="handleChangePagination"
    )
</template>

<script>
  // modules
  import inventoryGroupsModule from "@/config/store/matching/inventory_group"
  import sourcesInventoryGroupsModule from "@/config/store/sources/inventory_groups"
  import sourcesShopsModule from "@/config/store/shared_inventory_management/sources_shops"

  // mixins
  import withStoreModule from "@/mixins/withStoreModule"
  import withModal from "@/mixins/withModal"
  import withConfirmation from "@/mixins/withConfirmation"
  import withToyotaOrganizationsMountedOrder from "@/mixins/withToyotaOrganizationsMountedOrder"

  // components
  import InventoryGroupForm from "./InventoryGroupForm"

  // misc
  import { toObjectBy } from "@/helpers/common"
  import { appDebounce } from "@/helpers/common"

  const inventoryGroupsMixin = withStoreModule(inventoryGroupsModule, {
    resetState: true,
    name: "inventoryGroupMatching",
    readers: {
      inventoryGroups: "items",
      inventoryGroupsLoading: "loading",
      filters: "filters",
      pagination: "pagination",
      sortingData: "sorting"
    },
    mutations: {
      setFilters: "SET_FILTERS",
      setPagination: "SET_PAGINATION_DATA",
      setSorting: "SET_SORTING"
    },
    actions: {
      fetchInventoryGroups: "FETCH_ITEMS",
      createInventoryGroup: "CREATE_ITEM",
      updateInventoryGroup: "UPDATE_ITEM",
      deleteInventoryGroup: "DELETE_ITEM",
      checkInventoryGroupExists: "CHECK_INVENTORY_GROUP_EXISTS"
    }
  })

  const sourcesInventoryGroupsMixin = withStoreModule(sourcesInventoryGroupsModule, {
    name: "sourcesInventoryGroups",
    readers: {
      sourcesInventoryGroups: "items",
      sourcesInventoryGroupsLoading: "loading",
      selectedSources: "selectedItems"
    },
    actions: {
      fetchSourcesInventoryGroups: "FETCH_ITEMS",
      fetchSelectedSources: "FETCH_SELECTED_ITEMS"
    }
  })

  const sourcesShopsMixin = withStoreModule(sourcesShopsModule, {
    name: "sharedInventoryMasterSourcesShops",
    readers: {
      sourcesShopsByInventoryGroups: "items",
      sourcesShopsLoading: "loading"
    },
    actions: {
      fetchSourcesShops: "FETCH_ITEMS"
    }
  })

  export default {
    components: {
      TopBar: () => import("@/components/layout/TopBar"),
      AppOverlayLoader: () => import("@/components/elements/AppOverlayLoader"),
      InventoryGroupsTable: () => import("./InventoryGroupsTable"),
      AppPagination: () => import("@/components/elements/AppPagination")
    },

    mixins: [
      inventoryGroupsMixin,
      sourcesInventoryGroupsMixin,
      sourcesShopsMixin,
      withModal,
      withConfirmation,
      withToyotaOrganizationsMountedOrder
    ],

    mounted() {
      this.fetchInventoryGroups()
      this.fetchSourcesInventoryGroups()
      this.fetchSelectedSources()
      this.fetchSourcesShops()
    },

    computed: {
      otas() {
        return this.$store.getters.translatedOtaList
      }
    },

    methods: {
      handleAddNew() {
        this.openModal({
          title: this.$t("inventory_group_matching.modal.title_create"),
          action: this.createInventoryGroup
        })
      },

      handleEdit(inventoryGroup) {
        this.openModal({
          title: this.$t("inventory_group_matching.modal.title_update"),
          inventoryGroup: this.mappedInventoryGroup(inventoryGroup),
          action: this.updateInventoryGroup,
          props: {
            sourcesShopsByOta: this.sourcesShopsByInventoryGroups[inventoryGroup.id]
          }
        })
      },

      mappedInventoryGroup({ name, sources_inventory_groups, ...rest }) {
        return {
          ...rest,
          name,
          sources_inventory_groups: toObjectBy("ota_id", sources_inventory_groups)
        }
      },

      openModal({ title, action, inventoryGroup, props = {} }) {
        this.$openModal({
          title,
          component: InventoryGroupForm,
          closeOnClick: false,
          closeOnEsc: false,
          props: {
            ...props,
            action,
            successActionCallbacks: [this.fetchSelectedSources],
            otas: this.otas,
            inventoryGroup,
            loading: this.inventoryGroupsLoading || this.sourcesShopsLoading,
            sourcesInventoryGroups: this.sourcesInventoryGroups,
            selectedSources: this.selectedSources,
            inventoryGroupExistsRequest: this.checkInventoryGroupExists
          }
        })
      },

      handleDelete(inventoryGroup) {
        this.$confirm({
          title: this.$t("inventory_group_matching.delete_confirmation", { name: inventoryGroup.name }),
          resolve: {
            handler: async () => {
              await this.deleteInventoryGroup(inventoryGroup.id)
              this.fetchSelectedSources()
            }
          }
        })
      },

      debouncedFetchInventoryGroups: appDebounce(function() {
        this.fetchInventoryGroups()
      }),

      handleSearch(filters) {
        this.setFilters(filters)

        this.debouncedFetchInventoryGroups()
      },

      handleChangePagination(pagination) {
        this.setPagination({ ...this.pagination, ...pagination })

        this.fetchInventoryGroups()
      },

      handleSorting(sorting) {
        this.setSorting(sorting)

        this.fetchInventoryGroups()
      }
    }
  }
</script>

<style lang="sass" scoped>
  .inventory-group-matching
    position: relative
</style>
